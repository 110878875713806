var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar"),
      _c("div", { staticClass: "page-wrapper" }, [
        _c("div", { staticClass: "page-content" }, [
          _c("div", { staticClass: "content-title" }, [
            _vm._v("Add Collaborators"),
          ]),
          _c("div", { staticClass: "page-message" }, [
            _vm._v(
              "\n        Invite key stakeholders in your team to collaborate on this product.\n        Invite co-founders, CTOs so that everyone is on the same page\n      "
            ),
          ]),
          _c(
            "div",
            { staticClass: "grouped-form" },
            [
              _c("div", { staticClass: "form-label" }, [
                _vm._v("Enter email address(es)"),
              ]),
              _c("v-combobox", {
                staticClass: "overright-input",
                attrs: {
                  "append-icon": "none",
                  "persistent-hint": "",
                  id: "collaborators",
                  "small-chips": "",
                  solo: "",
                  flat: "",
                  placeholder: "collab@gmail.com",
                  color: "#979DA5",
                  multiple: "",
                },
                on: {
                  keypress: function ($event) {
                    return _vm.handleComma($event)
                  },
                },
                model: {
                  value: _vm.collaborators,
                  callback: function ($$v) {
                    _vm.collaborators = $$v
                  },
                  expression: "collaborators",
                },
              }),
              _c("div", { staticClass: "text-box-hint" }, [
                _vm._v(
                  "\n          Separate multiple email addresses with a comma or tab\n        "
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "action-buttons" }, [
            _c(
              "button",
              {
                staticClass: "grupa-white-btn",
                on: { click: _vm.handleProceed },
              },
              [_vm._v("\n          Skip for Now\n        ")]
            ),
            _c(
              "button",
              {
                staticClass: "grupa-blue-btn",
                on: { click: _vm.handleSendInvite },
              },
              [
                _vm._v("\n          Send Invite\n          "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spinner,
                      expression: "spinner",
                    },
                  ],
                  staticClass: "button-spinner",
                  staticStyle: { "margin-left": "20px" },
                  attrs: { src: "/img/lightbox/preloader.gif" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }