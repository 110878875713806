<template>
  <div>
    <Navbar />
    <div class="page-wrapper">
      <div class="page-content">
        <div class="content-title">Add Collaborators</div>
        <div class="page-message">
          Invite key stakeholders in your team to collaborate on this product.
          Invite co-founders, CTOs so that everyone is on the same page
        </div>
        <div class="grouped-form">
          <div class="form-label">Enter email address(es)</div>
          <v-combobox
            v-model="collaborators"
            append-icon="none"
            persistent-hint
            id="collaborators"
            small-chips
            solo
            flat
            placeholder="collab@gmail.com"
            color="#979DA5"
            class="overright-input"
            multiple
            @keypress="handleComma($event)"
          ></v-combobox>
          <div class="text-box-hint">
            Separate multiple email addresses with a comma or tab
          </div>
        </div>
        <div class="action-buttons">
          <button @click="handleProceed" class="grupa-white-btn">
            Skip for Now
          </button>
          <button class="grupa-blue-btn" @click="handleSendInvite">
            Send Invite
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 20px"
              class="button-spinner"
              v-show="spinner"
            />
          </button>
        </div>
      </div>
    </div>

    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>

<script>
import { inviteCollaborator } from "@/api/client";
import Navbar from "@/components/Navbar/onboarding_navbar";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
import { mapActions, mapState } from "vuex";

export default {
  props: {},
  components: {
    Navbar,
    AlertSuccess,
    AlertError,
  },

  data: () => ({
    collaborators: [],
    spinner: false,
  }),

  created() {},
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),

    handleComma(event) {
      if (event.key == "," || event.key == " ") {
        event.preventDefault();
        let el = document.getElementById("collaborators");
        var e = new KeyboardEvent("keydown", {
          bubbles: true,
          cancelable: true,
          char: "Tab",
          key: "Tab",
          shiftKey: true,
          keyCode: 9,
        });
        el.dispatchEvent(e);
      } else {
        event;
      }
    },
    handleSendInvite() {
      let project_id = this.$route.params.id;
      this.spinner = true;

      let emails = [];

      this.collaborators.forEach(element => {
          emails.push({email: element});
      });
      

      let data = {
        email: emails,
        permission: "Partner",
        project_id,
      };
      inviteCollaborator(data)
        .then((response) => {
          this.spinner = false;
          if (response.data.status == 1) {
            this.setSuccessMsg("Collaborator(s) have been invited.");
            setTimeout(() => {
              this.$router.push({
                name: "project_workspace",
                params: { id: project_id },
              });
            }, 2000);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data) {
              this.setErrorMsg(err.response.data.errors.email[0]);
            }
          }
          this.spinner = false;
        });
    },
    handleProceed() {
      let projectId = this.$route.params.id;
      this.$router.push({
        name: "project_workspace",
        params: { id: projectId },
      });
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.page-content {
  width: 410px;
  margin-top: 127px;
}
.content-title {
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
  text-align: left;
}
.page-message {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
  margin-top: 16px;
}
.form-label {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: left;
  margin: 48px 0px 4px 0px;
}
.text-box-hint {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
  margin-top: 8px;
}
.action-buttons {
  margin-top: 48px;
  display: flex;
  justify-content: flex-end;
}
.grupa-white-btn {
  margin-right: 16px;
}
</style>
<style>
.grouped-form .v-text-field {
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 130%;
}
</style>
